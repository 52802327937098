const getScrollParent = ({ wrapperClass }) => {
    const node = document.querySelector(`.${wrapperClass}`);
    // based on https://stackoverflow.com/a/49186677 (though updated to only be concerned with y-direction scrolling)
    const regex = /(auto|scroll)/;
    const parents = (_node, ps) => {
        if (_node.parentNode === null) {
            return ps;
        }
        return parents(_node.parentNode, ps.concat([_node]));
    };

    const style = (_node, prop) =>
        getComputedStyle(_node, null).getPropertyValue(prop);
    const overflow = (_node) => style(_node, "overflow-y");
    const scroll = (_node) => regex.test(overflow(_node));

    const scrollParent = (_node) => {
        if (!(_node instanceof HTMLElement || _node instanceof SVGElement)) {
            return;
        }

        const ps = parents(_node.parentNode, []);

        for (let i = 0; i < ps.length; i += 1) {
            if (scroll(ps[i])) {
                return ps[i];
            }
        }

        return document.scrollingElement || document.documentElement;
    };

    return scrollParent(node);
};

const getElementVerticalOverLap = (element) => {
    const getZIndex = (element) => {
        return (
            parseInt(
                window.getComputedStyle(element).getPropertyValue("z-index"),
                10
            ) || 0
        );
    };

    const getVerticalOverlap = (element, otherElement) => {
        const elementRect = element.getBoundingClientRect();
        const otherRect = otherElement.getBoundingClientRect();

        // Check if the two rectangles intersect
        if (
            !(
                elementRect.left < otherRect.right &&
                elementRect.right > otherRect.left &&
                elementRect.top < otherRect.bottom &&
                elementRect.bottom > otherRect.top &&
                getZIndex(element) <= getZIndex(otherElement)
            )
        )
            return 0;

        const overlapTop = Math.max(elementRect.top, otherRect.top);
        const overlapBottom = Math.min(elementRect.bottom, otherRect.bottom);
        const overlapHeight = overlapBottom - overlapTop;

        // Check if there is a vertical overlap
        if (overlapHeight > 0) return overlapHeight;

        return 0;
    };

    let largestVerticalOverlap = 0;

    // Iterate over all other elements on the page
    const allElements = document.querySelectorAll("*");
    for (const otherElement of allElements) {
        // Skip the current element and elements without a bounding box
        if (
            otherElement === element ||
            otherElement.getAttribute("aria-modal") == "true" ||
            !otherElement.getBoundingClientRect().width ||
            !otherElement.getBoundingClientRect().height
        ) {
            continue;
        }

        // Calculate the vertical overlap
        const overlap = getVerticalOverlap(element, otherElement);

        if (overlap > largestVerticalOverlap) largestVerticalOverlap = overlap;
    }

    return largestVerticalOverlap;
}

const getMenuHeight = ({ menuClass, verticalObstruction }) => {
    if (!verticalObstruction) return;
    const menu = document.querySelector(`.${menuClass}`);
    return Math.ceil(menu.clientHeight - verticalObstruction);
}

const observeMenu = ({ menuClass, menuHeight = 'auto' }) => {
    const menu = document.querySelector(`.${menuClass}`);

    let intersectionObserver, mutationObserver;
    let verticalObstruction = 0;

    if (menu && menuHeight === "auto") {
        intersectionObserver = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (
                    entry.isIntersecting &&
                    entry.boundingClientRect.width > 0 &&
                    entry.boundingClientRect.height > 0
                ) {
                    // Menu is in view and has non-zero dimensions
                    verticalObstruction = getElementVerticalOverLap(menu);
                    menuHeight = getMenuHeight({ menuClass, verticalObstruction });
                    intersectionObserver.disconnect();
                    mutationObserver.disconnect();
                } else {
                    // Menu is not in view or has zero dimensions
                    verticalObstruction = 0;
                }
            });
        });

        intersectionObserver.observe(menu);

        mutationObserver = new MutationObserver((mutations) => {
            mutations.forEach((mutation) => {
                if (
                    mutation.type === "attributes" &&
                    mutation.attributeName === "class"
                ) {
                    intersectionObserver.unobserve(menu);
                    var mutationMenu = document.querySelector(
                        `.${menuClass}`
                    );
                    intersectionObserver.observe(mutationMenu);
                }
            });
        });

        mutationObserver.observe(menu, { attributes: true });
    }

    return { mutationObserver, intersectionObserver, verticalObstruction, menuHeight };
}

const getRandomNumber = () => {
    return Math.floor(Math.random() * 100000);
}

const getMenuClass = () => {
    return `menu-${getRandomNumber()}`;
}

const getUniqueClass = () => {
    return `component-${getRandomNumber()}`;
}

export { getScrollParent, observeMenu, getElementVerticalOverLap, getMenuClass, getUniqueClass };