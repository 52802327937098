<template>
  <div class="checkbox">
    <v-checkbox
      v-bind="$attrs"
      v-on="$listeners"
      :input-value="isChecked"
      @change="onChange"
      indeterminate-icon="mdi-minus-box-outline"
      ref="checkbox"
    >
      <template v-slot:label>
        <div class="label" v-if="label">
          <p class="subtitle-1">{{ label }}</p>
          <v-icon v-if="icon" class="icon" size="16">{{ icon }}</v-icon>
        </div>
      </template>
      <template v-slot:message="{ message }">
        <p v-if="message" class="body-2 message">{{ message }}</p>
      </template>
    </v-checkbox>
    <slot name="append-label" />
    <p v-if="warning" class="body-2 warning">{{ warning }}</p>
  </div>
</template>

<script>
/**
 * @fileoverview Common component for checkboxes
 *
 * @author Cameron Bulock <cameron.bulock@quavo.com>
 */
export default {
  name: "Checkbox",
  props: {
    checkboxValue: {
      type: [String, Number, Boolean, Object],
      default: false,
    },
    label: {
      type: String,
    },
    icon: {
      type: String,
    },
    value: {
      type: [Boolean, Array],
      required: true,
    },
    warning: {
      type: String,
    },
  },
  computed: {
    isChecked() {
      if (Array.isArray(this.value)) {
        return this.value.includes(this.checkboxValue);
      }
      return this.value;
    },
  },
  methods: {
    onChange(checked) {
      if (Array.isArray(this.value)) {
        const newValue = checked
          ? [...this.value, this.checkboxValue]
          : this.value.filter((item) => item !== this.checkboxValue);
        this.$emit("input", newValue);
      } else {
        this.$emit("input", checked);
      }
    },
    validate() {
      return this.$refs.checkbox.validate();
    },
  },
};
</script>

<style lang="scss" scoped>
.checkbox {
  display: flex;
  gap: $qvo-spacing-16;
}
.v-input {
  margin: 0;
  padding-top: 0;
  &.v-input--checkbox.v-input--indeterminate.v-input--is-disabled {
    opacity: unset;
  }
  &::v-deep {
    .v-input__control .v-input__slot {
      margin: 0;
      align-items: flex-start;
      .v-input--selection-controls__input &.v-icon {
        color: $qvo-color-grey-500;
      }
      input {
        display: none;
      }
    }
    .v-input--selection-controls__input:hover,
    .v-input--selection-controls__ripple:before {
      background: none;
    }
    .v-messages {
      min-height: unset;
    }
  }
  &.v-input--is-label-active,
  &.v-input--indeterminate,
  &:hover {
    &::v-deep {
      .v-input__control
        .v-input__slot
        .v-input--selection-controls__input
        .v-icon {
        color: $qvo-color-blue-400;
      }
    }
  }
  &.v-input--is-disabled {
    .v-label {
      .v-icon {
        // icon to the right of the label
        color: $qvo-color-grey-200;
      }
    }
    &::v-deep {
      .v-input__control
        .v-input__slot
        .v-input--selection-controls__input
        .v-icon {
        color: $qvo-color-grey-200;
      }
      .subtitle-1 {
        color: $qvo-color-grey-200;
      }
    }
  }
  &.error--text {
    &::v-deep {
      .v-icon {
        color: $qvo-color-red-300;
      }
    }
  }
  .v-label {
    align-items: end;
    .v-icon {
      // icon to the right of the label
      color: $qvo-color-blue-400;
      font-size: 20px !important;
    }
  }
}
.message {
  margin-left: 34px;
  color: $qvo-color-red-300;
}

.warning {
  color: $qvo-color-warning;
}

.label {
  display: flex;
  align-items: flex-start;
  gap: $qvo-spacing-8;
  p {
    text-align: justify;
  }
}
</style>