<template>
  <v-chip v-bind="$attrs" v-on="$listeners" @click="(v) => $emit('click', v)">
    <v-icon v-if="leftIcon" class="left-icon">{{ leftIcon }}</v-icon>
    <p class="subtitle-2">
      <slot />
    </p>
    <v-icon v-if="rightIcon" class="right-icon">{{ rightIcon }}</v-icon>
  </v-chip>
</template>

<script>
/**
 * @fileoverview Common component for tags
 *
 * @author Cameron Bulock <cameron.bulock@quavo.com>
 */
export default {
  name: "Tag",
  props: {
    leftIcon: {
      type: String,
    },
    rightIcon: {
      type: String,
    },
  },
};
</script>

<style lang="scss" scoped>
.theme--light.v-chip:not(.v-chip--active) {
  background: $qvo-color-blue-200;
  &::v-deep {
    .subtitle-2 {
      color: $qvo-color-blue-500;
    }
    .v-icon {
      color: $qvo-color-blue-500;
    }
  }
  &.v-chip--disabled {
    opacity: 1;
    background: $qvo-color-grey-100;
    &::v-deep {
      .subtitle-2 {
        color: $qvo-color-grey-900;
      }
      .v-icon {
        color: $qvo-color-grey-900;
      }
    }
  }
}
.v-chip.v-slide-item--active {
  background: $qvo-color-blue-500;
  &::v-deep {
    .subtitle-2 {
      color: $qvo-color-grey-000;
    }
    .v-icon {
      color: $qvo-color-grey-000;
    }
  }
}
.left-icon {
  margin-right: 10px;
}
.right-icon {
  margin-left: 10px;
}
.v-icon {
  font-size: 16px;
}
</style>